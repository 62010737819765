
				var addMethods = require("../node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["worker_findShortestPath"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "3226891d582411f7a681.worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "3226891d582411f7a681.worker.js");
					addMethods(w, methods)
					
					return w
				}
			